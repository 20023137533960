import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {
  faArrowUpRightFromSquare,
  faCheck,
  faCircleInfo,
  faFloppyDiskCircleArrowRight,
  faTrash,
  faXmark
} from '@fortawesome/pro-light-svg-icons';
import {AbstractControl, FormGroup, UntypedFormBuilder, ValidationErrors, Validators} from '@angular/forms';
import * as creativeFormUtil from '../../util/creative-form.util';
import {noWhitespaceValidator} from '../../../shared/util/form/validators/no-whitespace.validator';
import {hasError, hasRequiredError} from 'src/app/shared/util/form/form-utils';
import {IBasicSelectOption} from '../../../shared/interface/ui/form/IBasicSelectOption';
import {AdUnitService} from '../../../ad-unit/service/ad-unit';
import {IAdUnit} from '../../../ad-unit/interface/IAdUnit';
import {IAsset} from '../../../asset/interface/IAsset';
import {Status} from '../../../shared/enum/Status';
import {getResolutionLabel} from '../../../asset/util/resolution.util';
import {IResolution} from '../../../asset/interface/IResolution';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {AssetListSelectComponent} from '../../../asset/component/asset-list-select/asset-list-select.component';
import {firstValueFrom, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {AssetService} from '../../../asset/service/asset.service';
import {CreativeService} from '../../service/creative.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AssetLinkService} from '../../../asset/service/asset-link/asset-link.service';
import {IAssetFormControl} from 'src/app/creative/interface/ICreativeForm';
import {ICreativeRaw} from '../../interface/ICreativeRaw';
import {BACK_ACTION} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {IAdUnitFormatAssetPair} from '../../interface/IAdUnitFormatAssetPair';
import {ProductsService} from '../../../product/service/product/products.service';
import {ICanDeactivateFormCmp} from '../../../shared/interface/ui/form/ICanDeactivateFormCmp';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {ICreativeDetails} from '../../interface/ICreativeDetails';
import {switchMap} from 'rxjs/operators';
import {IFormScrollableHandler} from '../../../shared/interface/ui/form/IFormScrollableHandler';
import {FormScrollableHandler} from '../../../shared/class/FormScrollableHandler';
import {IFormScrollable} from '../../../shared/interface/ui/form/IFormCmp';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {ProductEndpoints} from '../../../shared/enum/Endpoints';
import {ImageDataService} from '../../../shared/service/file-utils/image-data.service';
import {IAssetFileOutput} from '../../../asset/interface/IAssetFileOutput';
import {ICreativeFormGroup} from '../../interface/ICreativeFormGroup';
import {IAdUnitFormat} from '../../../ad-unit/interface/IAdUnitFormat';
import {AdUnitType} from '../../../shared/enum/AdUnitType';
import {CreativeFormService} from '../../service/creative-form.service';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';
import {FileUploadService} from '../../../asset/service/file-upload.service';
import {PresignedUrlPayload} from '../../../asset/interface/PresignedUrlPayload';

@Component({
  selector: 'app-creative-form',
  templateUrl: './creative-form.component.html',
  styleUrls: ['./creative-form.component.scss']
})
export class CreativeFormComponent implements OnInit, OnDestroy, ICanDeactivateFormCmp, AfterViewChecked, IFormScrollable {
  @ViewChild('formScrollable') formScrollable: ElementRef;
  private _hasScroll;

  @Input() public isDialog = false;
  @Input() public isEditMode = false;
  @Input() public initialValue: ICreativeDetails;

  public canDeactivateForm = false;

  public initialProductId: string;
  public formGroup: FormGroup<ICreativeFormGroup>;

  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;
  public faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  public faTrash = faTrash;
  public faCheck = faCheck;
  public faXMark = faXmark;
  public faCircleInfo = faCircleInfo;

  public hasRequiredError: (control: AbstractControl) => boolean = hasRequiredError;
  public hasError: (control: AbstractControl, errorCode: string) => boolean = hasError;
  public getResolutionLabel: (resolution: IResolution, ratio: string) => string = getResolutionLabel;
  public creativeFormUtil = creativeFormUtil;

  public adUnitOptions: (IBasicSelectOption & IAdUnit)[];
  public adUnits: IAdUnit[];
  public initialized = false;

  public assetsControls: IAssetFormControl[] = [];

  private subscriptions: Subscription[] = [];

  public promises: Promise<any>[] = [];

  public Status = Status;

  public isLoading = false;
  public isFormLoading = false;

  public closeDialogSubject = new Subject<boolean>();
  private formScrollableHandler: IFormScrollableHandler;

  constructor(private formBuilder: UntypedFormBuilder, private adUnitService: AdUnitService,
              private expandedDialogService: ExpandedDialogService, private assetService: AssetService,
              private creativeService: CreativeService,
              private router: Router, private assetLinkService: AssetLinkService,
              @Optional() @Inject(BACK_ACTION) public backAction: IBackAction, private productService: ProductsService,
              private translateService: TranslateService, public changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute, private loadingService: LoadingService,
              private imageDataService: ImageDataService,
              private creativeFormService: CreativeFormService,
              private snackbarService: SnackbarService,
              private fileUploadService: FileUploadService) {
    this.initialProductId = this.route.snapshot.queryParamMap.get('productId');
    this.formScrollableHandler = new FormScrollableHandler(this);
  }

  private static isSameAsset(oldAsset: IAsset, newAsset: IAsset): boolean {
    if (!oldAsset || !oldAsset?.id) {
      return false;
    } else {
      return oldAsset.id === newAsset?.id;
    }
  }

  public ngAfterViewChecked(): void {
    this.formScrollableHandler.setScrollStatus();
  }

  get isFormValid(): boolean {
    return this.formGroup?.valid && creativeFormUtil.isAssetListValid(this.assetsControls);
  }

  public ngOnInit(): void {
    this.creativeFormService.init(this.initialValue);
    this.observeLoading();
    this.initAdUnits().then(() => {
      this.initForm();
      if (this.initialValue?.adUnit) {
        this.initAdUnitForEdit();
      }
      this.initialized = true;
      this.observeAdUnitChanges();
      this.observeProductChanges();
      this.observeIsStaticChanges();
      this.observeDefaultHtmlChanges();
    });
  }

  public initForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [this.isEditMode ? this.initialValue.name : '', [Validators.required, noWhitespaceValidator],
        [this.creativeNameUnique.bind(this)]],
      product: [this.initialValue?.product ? this.initialValue.product : null, Validators.required],
      adUnit: [null, [Validators.required]],
      description: [this.initialValue?.description ? this.initialValue.description : ''],
      incentiveText: [this.initialValue?.incentiveText ? this.initialValue.incentiveText : ''],
      isStatic: [this.getIsStaticInitial()],
      useStaticForDefaultHtml: [this.getUseStaticForDefaultHtml()]
    });

    if (this.initialProductId) {
      this.updateInitialProduct();
    }
  }

  private getIsStaticInitial(): boolean {
    return this.isEditMode && this.creativeFormService.isInitialCreativeStatic(this.initialValue, this.adUnits);
  }

  private getUseStaticForDefaultHtml(): boolean {
    return this.isEditMode && this.initialValue.useStaticForDefaultHtml;
  }

  private async updateInitialProduct(): Promise<void> {
    this.productService.getProductByInternalId(this.initialProductId)
      .then((product) => this.formGroup.controls.product.setValue(product))
      .catch(() => this.formGroup.controls.product.setValue(null));
  }

  public async initAdUnits(): Promise<void> {
    this.adUnits = await this.adUnitService.getAdUnitsFromSubject();
    this.adUnitOptions = this.adUnits.map(el => ({...el, label: el.name}));
  }

  private initAdUnitForEdit(): void {
    const adUnit: IBasicSelectOption & IAdUnit = this.adUnitOptions.find(adUnitOption => adUnitOption.id === this.initialValue.adUnit.id);
    this.formGroup.get('adUnit').setValue(adUnit);
    if (this.initialValue.assets) {
      this.initEditAssetsControls(adUnit as unknown as IAdUnit);
    } else {
      this.setNewAssetsControls(adUnit as unknown as IAdUnit);
    }
  }

  public observeAdUnitChanges(): void {
    this.formGroup.get('adUnit').valueChanges.subscribe((value) => {
      this.setNewAssetsControls(value as IAdUnit);
    });
  }

  private observeIsStaticChanges(): void {
    this.formGroup.controls.isStatic.valueChanges.subscribe((value) => {
      this.formGroup.controls.useStaticForDefaultHtml.setValue(value);
      this.setNewAssetsControls(this.formGroup.controls.adUnit.value);
    });
  }

  private observeDefaultHtmlChanges(): void {
    this.formGroup.controls.useStaticForDefaultHtml.valueChanges.subscribe(() => {
      this.setNewAssetsControls(this.formGroup.controls.adUnit.value);
    });
  }

  public observeProductChanges(): void {
    this.formGroup.get('product').valueChanges.subscribe(() => {
      this.setNewAssetsControls(this.formGroup.value.adUnit as IAdUnit);
    });
  }

  private observeLoading(): void {
    const subscription = this.loadingService.loadingEndpointsObs()
      .subscribe((loadingState) => {
        this.isFormLoading = this.loadingService.areEndpointsLoading(this._loadingEndpointNames(), loadingState);
      });
    this.subscriptions.push(subscription);
  }

  public initEditAssetsControls(adUnit: IAdUnit): void {
    if (!adUnit) {
      this.setAssetControls([], undefined, undefined);
    } else if (adUnit?.type === AdUnitType.REWARDED || adUnit?.type === AdUnitType.INTERSTITIAL) {
      const adUnitFormats = this.formGroup.controls.isStatic.value ?
        this.creativeFormService.getAdFormatsForStatic(adUnit, this.initialValue.useStaticForDefaultHtml) :
        this.creativeFormService.getAdFormatsForNonStatic(adUnit);
      this.setAssetControls(adUnitFormats, adUnit.id, adUnit.name, true);
    } else {
      this.setAssetControls(adUnit.adUnitFormats, adUnit.id, adUnit.name, true);
    }
  }

  public setNewAssetsControls(adUnit: IAdUnit): void {
    if (!adUnit) {
      this.setAssetControls([], undefined, undefined);
    } else if (this.isRewardedOrInterstitial()) {
      this.setControlsForRewardedInterstitial(adUnit);
    } else {
      this.setAssetControls(adUnit.adUnitFormats, adUnit.id, adUnit.name);
    }
  }

  private setControlsForRewardedInterstitial(adUnit: IAdUnit): void {
    const useDefaultHtml = this.formGroup.controls.useStaticForDefaultHtml.value;
    const adUnitFormats = this.formGroup.controls.isStatic.value ?
      this.creativeFormService.getAdFormatsForStatic(adUnit, useDefaultHtml) :
      this.creativeFormService.getAdFormatsForNonStatic(adUnit);
    this.setAssetControls(adUnitFormats, adUnit.id, adUnit.name);
  }

  public onCancelClicked(): void {
    this.closeForm(false);
  }

  private closeForm(omitConfirmation: boolean): void {
    if (this.isDialog) {
      this.closeDialogSubject.next(omitConfirmation);
    } else {
      this.router.navigate(['/creative/list']);
    }
  }

  public async onSubmitClicked(): Promise<void> {
    this.isLoading = true;
    if (this.isEditMode) {
      this.updateCreative();
    } else {
      this.createCreative();
    }
  }

  public isSubmitDisabled(): boolean {
    return !this.isFormValid || this.formGroup?.pending || this.assetsControls?.some(el => el.isMatchValidationLoading);
  }

  public isPendingButonVisible(): boolean {
    return this.formGroup?.pending || this.assetsControls?.some(el => el.isMatchValidationLoading);
  }

  private async createCreative(): Promise<void> {
    const creativeRaw: ICreativeRaw = await this.getCreativeRaw();

    this.creativeService.addCreative(creativeRaw).then(async () => {
      const message = await firstValueFrom(this.translateService.get('creative.creativeCreatedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.canDeactivateForm = true;
      this.closeForm(true);
    })
      .finally(() => this.isLoading = false);
  }

  private async updateCreative(): Promise<void> {
    const creativeRaw: ICreativeRaw = await this.getCreativeRaw();
    creativeRaw.id = this.initialValue.id;

    this.creativeService.editCreative(creativeRaw).then(async () => {
      const message = await firstValueFrom(this.translateService.get('creative.creativeEditedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.closeForm(true);
    }).finally(() => this.isLoading = false);
  }

  private async getCreativeRaw(): Promise<ICreativeRaw> {
    let newAssets: IAdUnitFormatAssetPair[];
    if (this.fileUploadService.canUploadUsingPresignedUrls()) {
      newAssets = await this.createNewAssetsPresigned();
    } else {
      newAssets = await this.createNewAssets();
    }
    const existingAssets = this.getExistingAssets();
    return this.creativeFormService.getCreativeRaw(this.formGroup, newAssets, existingAssets);
  }

  private getExistingAssets(): IAdUnitFormatAssetPair[] {
    return this.assetsControls.map(assetControl =>
      ({assetId: assetControl?.asset?.id, adUnitFormatId: assetControl.adUnitFormat.id}))
      .filter(el => el.assetId);
  }

  public async createNewAssetsPresigned(): Promise<IAdUnitFormatAssetPair[]> {
    const assets: IAdUnitFormatAssetPair[] = [];
    const newAssetControls = this.getNewAssetsControls();
    await this.handleFileUploadUsingPresignedUrls(newAssetControls);

    for (const assetControl of newAssetControls) {
      try {
        const assetId = await this.createAssetPresigned(assetControl);
        assets.push({assetId, adUnitFormatId: assetControl.adUnitFormat.id});
      } catch (error) {
        this.isLoading = false;
        throw new Error('Asset Creation Failed');
      }
    }
    return assets;
  }

  public async createNewAssets(): Promise<IAdUnitFormatAssetPair[]> {
    const assets: IAdUnitFormatAssetPair[] = [];
    for (const assetControl of this.getNewAssetsControls()) {
      try {
        const assetId = await this.createAsset(assetControl);
        assets.push({assetId, adUnitFormatId: assetControl.adUnitFormat.id});
      } catch (error) {
        this.isLoading = false;
        throw new Error('Asset Upload Failed');
      }
    }
    return assets;
  }

  private getNewAssetsControls(): IAssetFormControl[] {
    return this.assetsControls.filter(el => el.asset && !el.asset.id);
  }

  private async handleFileUploadUsingEndpoint(assetControl: IAssetFormControl): Promise<void> {
    const newFile = new File([assetControl.file], assetControl.asset.name, {type: assetControl.file.type});
    const boombitId = this.formGroup.value.product.boombitId;
    await this.assetService.uploadFile(newFile, boombitId);
    assetControl.asset.url = this.imageDataService.getFileSrcUrl(assetControl.asset.name, boombitId);
  }

  private async handleFileUploadUsingPresignedUrls(assetControls: IAssetFormControl[]): Promise<void> {
    const boombitId = this.formGroup.value.product.boombitId;
    const payload: PresignedUrlPayload[] = assetControls.map(assetControl =>
      ({originalName: assetControl.file.name, boombitId, contentType: assetControl.file.type}));
    const presignedUrlResponse = await this.assetService.generatePresignedUrls(payload);

    for (const assetControl of assetControls) {
      const fileuploadurl = presignedUrlResponse[assetControl.file.name]?.url;
      const newFileName = presignedUrlResponse[assetControl.file.name].generatedName + '.' + assetControl.file.name.split('.').pop();
      const newFile = new File([assetControl.file], newFileName, {type: assetControl.file.type});
      await firstValueFrom(this.fileUploadService.uploadFileAWSS3(fileuploadurl, assetControl.file.type, newFile));
      assetControl.asset.url = presignedUrlResponse[assetControl.file.name]?.assetUrl;
    }
  }

  private async createAssetPresigned(assetControl: IAssetFormControl): Promise<string> {
    let assetCreated: IAsset;
    try {
      assetCreated = await this.assetService.createAsset(this.formGroup.value.product.id, assetControl.asset);
    } catch (error) {
      throw new Error('Asset Create Failed');
    }
    return assetCreated.id;
  }

  private async createAsset(assetControl: IAssetFormControl): Promise<string> {
    await this.uploadAsset(assetControl);
    let assetCreated: IAsset;
    try {
      assetCreated = await this.assetService.createAsset(this.formGroup.value.product.id, assetControl.asset);
    } catch (error) {
      throw new Error('Asset Create Failed');
    }
    return assetCreated.id;
  }

  private async uploadAsset(assetControl: IAssetFormControl): Promise<void> {
    const uploadPromise = this.handleFileUploadUsingEndpoint(assetControl);
    await uploadPromise;
  }

  public onAssetDelete(assetControl: IAssetFormControl): void {
    this.creativeFormService.handleAssetDelete(assetControl);
  }

  public async onFileSelected(data: IAssetFileOutput, assetControl: IAssetFormControl): Promise<void> {
    const oldAssetControl = _.cloneDeep(assetControl);
    assetControl.file = data.file;
    assetControl.asset = data.asset;
    await this.checkNameUnique(assetControl);
    await this.checkAdUnitFormatMatch(assetControl);
    creativeFormUtil.setAssetStatus(assetControl);
    this.setRandomNameForAsset(assetControl);
    this.handleAssetUpdatedMsg(oldAssetControl, assetControl);
  }

  private handleAssetUpdatedMsg(oldAssetControl: IAssetFormControl, assetControl: IAssetFormControl): void {
    if (this.shouldShowSwappedMsg(oldAssetControl, assetControl)) {
      this.showAssetSwappedMsg();
    }
  }

  private shouldShowSwappedMsg(oldAssetControl: IAssetFormControl, newAssetControl: IAssetFormControl): boolean {
    return oldAssetControl.asset && !(newAssetControl.status === Status.ERROR) && this.isEditMode &&
      !CreativeFormComponent.isSameAsset(oldAssetControl.asset, newAssetControl.asset);
  }

  private showAssetSwappedMsg(): void {
    this.translateService.get('creative.creativeForm.assetSwappedMessage').subscribe((message) =>
      this.snackbarService.openSuccessSnackbar(message));
  }

  private async setRandomNameForAsset(assetControl: IAssetFormControl): Promise<void> {
    const assetNamePromise = this.assetService.getRandomAssetName(30);
    this.promises.push(assetNamePromise);
    assetControl.asset.name = await assetNamePromise + '.' + assetControl.asset.format;
  }

  private async checkNameUnique(assetControl: IAssetFormControl): Promise<void> {
    const namePromise = this.assetService.checkOriginalName(assetControl.asset.originalName);
    this.promises.push(namePromise);
    assetControl.nameExistsError = await namePromise;
  }

  private async checkAdUnitFormatMatch(assetControl: IAssetFormControl): Promise<void> {
    assetControl.isMatchValidationLoading = true;
    const newPromise = this.assetService.getAssetAdUnitFormats(assetControl.asset);
    this.promises.push(newPromise);
    let adUnitFormats: IAdUnitFormat[];
    try {
      adUnitFormats = await newPromise;
      assetControl.isMatchValidationLoading = false;
    } catch (error) {
      adUnitFormats = [];
      assetControl.isMatchValidationLoading = false;
    }
    assetControl.adUnitFormatMatchError = !adUnitFormats?.some(el => el.id === assetControl.adUnitFormat.id);
  }

  public onOpenAssetLibraryClicked(assetControl: IAssetFormControl): void {
    const component = this.expandedDialogService.open(AssetListSelectComponent);
    component.instance.adUnitId = assetControl.adUnitId;
    component.instance.product = this.formGroup.value.product;
    component.instance.adUnitFormatId = assetControl.adUnitFormat.id;
    component.instance.adUnitFormatName = assetControl.adUnitFormat.name;
    const subscription = component.instance.assetSelected.subscribe((asset) => {
      const oldAssetControl = _.cloneDeep(assetControl);
      assetControl.asset = asset;
      creativeFormUtil.setAssetStatus(assetControl);
      this.handleAssetUpdatedMsg(oldAssetControl, assetControl);
    });
    this.subscriptions.push(subscription);
  }

  public creativeNameUnique(control: AbstractControl): Observable<ValidationErrors | null> {
    if (this.initialValue?.name && this.initialValue.name === control.value) {
      return of(null);
    } else {
      return timer(1000).pipe(switchMap(async () => {
        const creativeNameExists: boolean = await this.creativeService.validateCreativeName(control.value);
        return creativeNameExists ? {creativeNameExists: true} : null;
      }));
    }
  }

  private setAssetControls(adUnitFormats: IAdUnitFormat[], adUnitId: string, adUnitName: string, editInit?: boolean): void {
    this.assetsControls = this.creativeFormService.getAssetControls(adUnitFormats, adUnitId, adUnitName, editInit);
  }

  public isMoreGamesSelected(): boolean {
    return this.formGroup.controls.adUnit.value?.type === AdUnitType.MORE_GAMES;
  }

  public isRewardedOrInterstitial(): boolean {
    const adUnitType = this.formGroup.controls.adUnit.value?.type;
    return adUnitType === AdUnitType.REWARDED || adUnitType === AdUnitType.INTERSTITIAL;
  }

  public isStatic(): boolean {
    return this.formGroup.controls.isStatic.value;
  }

  public get hasScroll(): boolean {
    return this._hasScroll;
  }

  public set hasScroll(value: boolean) {
    this._hasScroll = value;
  }

  private _loadingEndpointNames(): string[] {
    return [ProductEndpoints.getProductByInternalId(this.initialProductId)];
  }

  public get columnsCount(): number {
    const adUnitName =  this.formGroup?.controls?.adUnit?.value?.name;
    return this.creativeFormService.getColumnsCount(adUnitName);
  }

  public ngOnDestroy(): void {
    this.creativeFormService.destroy();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
